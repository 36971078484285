import React, { useState, useEffect, useCallback } from "react"
import { X, ChevronRight } from "lucide-react"
import { RiSpeakAiLine } from "react-icons/ri"
import { MdOutlineLightMode } from "react-icons/md"
import "./H2.css"
import hi from "../Assets/h211.png"

const FEMALE_VOICES = [
  "Google UK English Female",
  "Microsoft Zira Desktop",
  "Samantha",
  "Karen",
  "Tessa",
  "Serena",
  "Melina",
  "Veena",
  "Allison",
  "Ava",
]

const SlideProgress = ({ current, total }) => (
  <div className="progress-container">
    <div className="progress-bar" style={{ width: `${((current + 1) / total) * 100}%` }} />
  </div>
)

const CodeEditor = ({ code, title }) => (
  <div className="code-editor">
    <div className="editor-header">
      <span>{title}</span>
      <div className="editor-buttons">
        <div className="editor-button red"></div>
        <div className="editor-button yellow"></div>
        <div className="editor-button green"></div>
      </div>
    </div>
    <div className="editor-content">
      <pre className="code-text">
        <code>{code}</code>
      </pre>
    </div>
  </div>
)

const H2 = ({ onClose }) => {
  const content = [
    {
      type: "intro",
      title: "Headings and Paragraphs",
      description:
        "Welcome to the world of HTML structure! In this lesson, we'll explore how headings and paragraphs form the backbone of web content. Think of them as the skeleton and muscles of your web page body!",
      image: `<img src=${hi} alt="HTML Headings and Paragraphs" class="lesson-image" />`,
    },
    {
      title: "Heading Tags",
      description:
        "Heading tags are used to display titles (heading elements). These tags range from <h1> to <h6>, <h1> being the largest and <h6> being the smallest.",
      visual: (
        <div className="split-view">
          <CodeEditor 
            title="index.html"
            code={`<h1>Heading 1</h1>
<h2>Heading 2</h2>
<h3>Heading 3</h3>
<h4>Heading 4</h4>
<h5>Heading 5</h5>
<h6>Heading 6</h6>`}
          />
          <CodeEditor 
            title="Output"
            code={
              <div className="output-preview">
                <h1>Heading 1</h1>
                <h2>Heading 2</h2>
                <h3>Heading 3</h3>
                <h4>Heading 4</h4>
                <h5>Heading 5</h5>
                <h6>Heading 6</h6>
              </div>
            }
          />
        </div>
      ),
    },
    {
      title: "Paragraph Tag",
      description:
        "The <p> tag defines a paragraph.  Every new paragraph starts fresh on a new line, giving your content clarity and style.",
      visual: (
        <div className="lunchbox">
          <h3>📝 PARAGRAPH TAG: The "Lunchbox" of Text!</h3>
          <div className="lunchbox-content">
            <p>&lt;p&gt; = Your sandwich container 🥪</p>
            <ul>
              <li>Holds your main content (text, like a yummy filling).</li>
              <li>Every lunchbox starts on a new line (just like &lt;p&gt;!).</li>
            </ul>
          </div>
        </div>
      ),
    },
    {
      title: "Comments",
      description:
        "The magical invisible ink! Just like a secret diary, the text between <!-- --> is hidden from view. Use them to leave witty jokes, explain tricky code, or hide your brilliant secrets. (Remember: <!-- --> = Invisible ink!)",
      visual: (
        <div className="split-view compact">
          <CodeEditor 
            title="index.html"
            code={
              <>
                <span style={{ color: "crimson" }}>&lt;!-- This is a comment --&gt;</span>
                {"\n"}
                &lt;h1&gt;Visible Heading&lt;/h1&gt;
                {"\n\n"}
                <span style={{ color: "crimson" }}>
                  &lt;!-- This section explains{"\n"}     paragraphs --&gt;
                </span>
                {"\n"}
                &lt;p&gt;Visible paragraph text&lt;/p&gt;
              </>
            }
          />
          <CodeEditor 
            title="Output"
            code={
              <div className="output-preview">
                <h1>Visible Heading</h1>
                <p>Visible paragraph text</p>
              </div>
            }
          />
        </div>
      ),
    },
    {
      type: "outro",
      title: "Great job!",
      description:
        "You've completed the lesson on HTML headings, paragraphs, and comments. Now let's put this knowledge into practice!",
      button: "Start Practical",
    },
  ]

  const [currentSlide, setCurrentSlide] = useState(0)
  const [showHint, setShowHint] = useState(true)
  const [isDarkMode, setIsDarkMode] = useState(false)
  const [isSpeaking, setIsSpeaking] = useState(false)
  const [synth, setSynth] = useState(null)
  const [voices, setVoices] = useState([])

  useEffect(() => {
    if ("speechSynthesis" in window) {
      const synthInstance = window.speechSynthesis
      setSynth(synthInstance)
      const loadVoices = () => {
        const voicesList = synthInstance.getVoices()
        setVoices(voicesList.filter((v) => v.lang.startsWith("en")))
      }
      synthInstance.onvoiceschanged = loadVoices
      loadVoices()
    }
  }, [])

  const speakSlide = useCallback(() => {
    if (!synth) return
    synth.cancel()

    const slide = content[currentSlide]
    const text = `${slide.title}. ${slide.description}`
    const utterance = new SpeechSynthesisUtterance(text)
    utterance.rate = 1.05
    utterance.pitch = 1.25
    utterance.volume = 1
    utterance.lang = "en-US"

    const femaleVoice = voices.find(
      (voice) => FEMALE_VOICES.some((name) => voice.name.includes(name)) || voice.gender === "female",
    )
    if (femaleVoice) {
      utterance.voice = femaleVoice
      utterance.voiceURI = femaleVoice.voiceURI
    }

    utterance.onstart = () => setIsSpeaking(true)
    utterance.onend = () => setIsSpeaking(false)
    utterance.onerror = () => setIsSpeaking(false)

    synth.speak(utterance)
  }, [synth, currentSlide, content, voices])

  const toggleVoice = () => {
    if (isSpeaking) {
      window.speechSynthesis.cancel()
      setIsSpeaking(false)
    } else {
      speakSlide()
    }
  }

  const handlePrevious = useCallback(() => setCurrentSlide((prev) => Math.max(0, prev - 1)), [])
  const handleNext = useCallback(() => {
    setCurrentSlide((prev) => Math.min(content.length - 1, prev + 1))
    setShowHint(false)
  }, [content.length])

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === "ArrowRight") handleNext()
      if (e.key === "ArrowLeft") handlePrevious()
      if (e.key === "Enter" && currentSlide === content.length - 1) {
        alert("Let's start the practical!")
      }
    },
    [currentSlide, handleNext, handlePrevious, content.length],
  )

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress)
    return () => {
      window.speechSynthesis.cancel()
      window.removeEventListener("keydown", handleKeyPress)
    }
  }, [handleKeyPress])

  const toggleDarkMode = () => {
    setIsDarkMode((prev) => !prev)
  }

  const renderSlideContent = () => {
    const slide = content[currentSlide]
    if (slide.type === "intro" || slide.type === "outro") {
      return (
        <div className="special-slide">
          <h1>{slide.title}</h1>
          <p>{slide.description}</p>
          {slide.image &&
            (typeof slide.image === "string" ? <div dangerouslySetInnerHTML={{ __html: slide.image }} /> : slide.image)}
          {slide.type === "outro" && (
            <button className="start-button" onClick={() => alert("Let's start the practical!")}>
              {slide.button}
            </button>
          )}
        </div>
      )
    }
    return (
      <div className="regular-slide">
        <div className="slide-header">
          <h2>{slide.title}</h2>
          <p>{slide.description}</p>
        </div>
        {slide.visual}
      </div>
    )
  }

  return (
    <div className={`slides-container ${isDarkMode ? "dark-mode" : ""}`}>
      <SlideProgress current={currentSlide} total={content.length} />

      <button
        className="close-button"
        onClick={() => {
          window.speechSynthesis.cancel()
          onClose()
        }}
      >
        <X size={24} />
      </button>

      <button className={`voice-btn ${isSpeaking ? "speaking" : ""}`} onClick={toggleVoice}>
        <div className="voice-container">
          <RiSpeakAiLine size={24} />
          {isSpeaking && (
            <div className="sound-waves">
              {[...Array(3)].map((_, i) => (
                <span key={i} className="wave" style={{ animationDelay: `${i * 0.2}s` }} />
              ))}
            </div>
          )}
        </div>
      </button>

      <button className="dark-mode-button" onClick={toggleDarkMode} title="Toggle Dark Mode">
        <MdOutlineLightMode size={24} color={isDarkMode ? "#fbbf24" : "#2d3748"} />
      </button>

      {renderSlideContent()}

      {showHint && currentSlide === 0 && (
        <div className="hint-message">
          Click on the arrow (or press the → key)
          <ChevronRight className="chevron" size={20} />
        </div>
      )}

      <div className="slide-navigation">
        <button className="nav-arrow prev" onClick={handlePrevious} disabled={currentSlide === 0}>
          ◀
        </button>
        <button className="nav-arrow next" onClick={handleNext} disabled={currentSlide === content.length - 1}>
          ▶
        </button>
      </div>
    </div>
  )
}

export default H2
