import React from 'react';
import './Footer.css';
import appstore from "../../assets/images/appstore.png";
import playstore from "../../assets/images/playstore.png";
import youtubeIcon from "../../assets/icons/Youtube.png";
import linkedinIcon from "../../assets/icons/Linkedin.png";
import instagramIcon from "../../assets/icons/Instagram.png";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="top-links">
        <a href="#"><span className="icon">📚</span> To the learning portal</a>
        <div className="custom-divider5" />
        <a href="#"><span className="icon">✏️</span> To the project portal</a>
        <div className="custom-divider5" />
        <a href="#"><span className="icon">💼</span> To the job portal</a>
      </div>
      
      <div className="middle-links">
        <a href="/about"><span className="icon">👥</span> About Us</a>
        <div className="custom-divider5" />
        <a href="/careers"><span className="icon">🔍</span> Jobs at inelf</a>
        <div className="custom-divider5" />
        <a href="/companies"><span className="icon">🏢</span> For companies</a>
        <div className="custom-divider5" />
        <a href="/business"><span className="icon">🤝</span> Partnerships</a>
        <div className="custom-divider5" />
        <a href="/contact"><span className="icon">🎧</span> Contact Us</a>
      </div>
      
      <div className="social-icons">
        <a href="#" className="youtube" target="_blank">
          <img src={youtubeIcon} alt="YouTube" className="social-icon" />
        </a>
        <a href="#" className="linkedin" target="_blank">
          <img src={linkedinIcon} alt="LinkedIn" className="social-icon" />
        </a>
        <a href="#" className="instagram" target="_blank">
          <img src={instagramIcon} alt="Instagram" className="social-icon" />
        </a>
      </div>
      
      <div className="app-stores">
        <a href="#" className="app-store">
          <img style={{width:"150px"}} src={appstore} alt="App Store" />
        </a>
        <a href="#" className="google-play">
          <img style={{width:"150px", height:"160px"}} src={playstore} alt="Google Play" />
        </a>
      </div>

      <div className="bottom-links"> 
        <a href="#">Terms and Conditions</a>
        <a href="#">Privacy policy</a>
        <a href="#">Data protection</a>
        <a href="#">Privacy settings</a>
        <a href="#">Give us feedback</a>
      </div>
      
      <div className="f">
      © 2025 inelf, Inc. All Rights Reserved.
      </div>
    </footer>
  );
}
