import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import { dracula } from '@uiw/codemirror-theme-dracula';
import { 
  Copy, RotateCcw, Eye, ArrowRight, Zap, Brain, CheckCircle2, 
  Sun, Moon, BookOpen, Code2, Target, FileCode, ExternalLink, 
  ChevronLeft, ChevronRight, Award, Timer, Lock, Rocket, Trophy 
} from 'lucide-react';
import toast, { Toaster } from 'react-hot-toast';
import ConfettiGenerator from 'confetti-js';
import styles from './Ha.module.css';

const exercises = [
  {
    id: 1,
    title: "HTML Heading",
    taskNumber: "Task 01",
    difficulty: "Beginner",
    instructions: "Surround 'Hello Developer' with h1 tags",
    expectedCode: "<h1>Hello Developer</h1>",
    expectedResult: "<h1>Hello Developer</h1>",
    hints: [
      "Start with the opening <h1> tag",
      "Add 'Hello Developer' inside the tags",
      "Close with </h1>"
    ],
    resources: [
      { title: "HTML Headings Guide", url: "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements", icon: BookOpen }
    ]
  },
  {
    id: 2,
    title: "HTML Paragraph",
    taskNumber: "Task 02",
    difficulty: "Beginner",
    instructions: "Create a paragraph with 'Welcome to Web Development'",
    expectedCode: "<p>Welcome to Web Development</p>",
    expectedResult: "<p>Welcome to Web Development</p>",
    hints: [
      "Use the <p> tag for paragraphs",
      "Close the tag with </p>"
    ],
    resources: [
      { title: "HTML Paragraphs Guide", url: "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/p", icon: BookOpen }
    ]
  }
];

const achievements = [
  { id: "first_complete", title: "First Steps", description: "Complete your first exercise", icon: Rocket },
  { id: "no_hints", title: "Solo Coder", description: "Complete an exercise without hints", icon: Award },
  { id: "speed_demon", title: "Speed Demon", description: "Complete an exercise in under 30 seconds", icon: Timer },
  { id: "all_complete", title: "Master Coder", description: "Complete all exercises", icon: Trophy }
];

const Ha = () => {
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
  const [completedExercises, setCompletedExercises] = useState([]);
  const [currentCode, setCurrentCode] = useState('');
  const [hintsRevealed, setHintsRevealed] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [exerciseStates, setExerciseStates] = useState({});
  const [startTime, setStartTime] = useState(null);
  const [currentTimeSpent, setCurrentTimeSpent] = useState(0);
  const [totalPoints, setTotalPoints] = useState(0);
  const [theme, setTheme] = useState('dark');
  const [editorView, setEditorView] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const [showAchievements, setShowAchievements] = useState(false);
  const [unlockedAchievements, setUnlockedAchievements] = useState([]);

  const canvasRef = useRef(null);
  const confettiRef = useRef(null);

  const currentExercise = exercises[currentExerciseIndex];

  useEffect(() => {
    const savedUnlocked = JSON.parse(localStorage.getItem('unlockedAchievements') || '[]');
    setUnlockedAchievements(savedUnlocked);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const confettiSettings = { target: canvas, max: 80, size: 1.5, animate: true };
    confettiRef.current = new ConfettiGenerator(confettiSettings);
    return () => confettiRef.current.clear();
  }, []);

  const getExerciseState = (id) => {
    return exerciseStates[id] || { code: '', hintsRevealed: 0, timeSpent: 0, points: 0, attempts: 0 };
  };

  const updateExerciseState = (id, updates) => {
    setExerciseStates(prev => ({
      ...prev,
      [id]: { ...getExerciseState(id), ...updates }
    }));
  };

  const saveCurrentState = () => {
    updateExerciseState(currentExercise.id, {
      code: currentCode,
      hintsRevealed,
      timeSpent: startTime ? currentTimeSpent : getExerciseState(currentExercise.id).timeSpent,
      attempts: getExerciseState(currentExercise.id).attempts + (submitted ? 1 : 0)
    });
  };

  useEffect(() => {
    saveCurrentState();
    const state = getExerciseState(currentExercise.id);
    setCurrentCode(state.code);
    setHintsRevealed(state.hintsRevealed);
    setCurrentTimeSpent(state.timeSpent);
    setStartTime(null);
    setSubmitted(false);
    setShowAnswer(false);
  }, [currentExerciseIndex]);

  const isCorrect = useMemo(() => currentCode.trim() === currentExercise.expectedCode.trim(), [currentCode, currentExercise]);

  useEffect(() => {
    let interval;
    if (startTime && !isCorrect) {
      interval = setInterval(() => {
        setCurrentTimeSpent(Math.floor((Date.now() - startTime) / 1000));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [startTime, isCorrect]);

  useEffect(() => {
    if (isCorrect && startTime) {
      const finalTimeSpent = currentTimeSpent;
      updateExerciseState(currentExercise.id, { timeSpent: finalTimeSpent });
      setStartTime(null);
      if(finalTimeSpent < 30 && !unlockedAchievements.includes('speed_demon')) {
        unlockAchievement('speed_demon');
      }
    }
  }, [isCorrect]);

  const handleCodeChange = (value) => {
    setCurrentCode(value);
    if (!startTime && !isCorrect) setStartTime(Date.now());
  };

  const handleReset = () => {
    setCurrentCode('');
    setShowAnswer(false);
    setSubmitted(false);
    setHintsRevealed(0);
    setStartTime(null);
    setCurrentTimeSpent(0);
    toast.success('Code reset successfully!');
  };

  const handleShowAnswer = () => {
    setShowAnswer(true);
    setCurrentCode(currentExercise.expectedCode);
    toast.success('Answer revealed!');
  };

  const unlockAchievement = (achievementId) => {
    if (!unlockedAchievements.includes(achievementId)) {
      const newUnlocked = [...unlockedAchievements, achievementId];
      setUnlockedAchievements(newUnlocked);
      localStorage.setItem('unlockedAchievements', JSON.stringify(newUnlocked));
      const achievement = achievements.find(a => a.id === achievementId);
      toast.success(`🏆 Achievement Unlocked: ${achievement.title}!`);
    }
  };

  const handleSubmit = () => {
    setSubmitted(true);
    if (isCorrect && !completedExercises.includes(currentExercise.id)) {
      const timeBonus = currentTimeSpent < 60 ? 50 : currentTimeSpent < 120 ? 25 : 0;
      const hintPenalty = hintsRevealed * 10;
      const points = Math.max(0, 100 + timeBonus - hintPenalty);
      updateExerciseState(currentExercise.id, { points });
      setCompletedExercises(prev => [...prev, currentExercise.id]);
      setTotalPoints(prev => prev + points);
      confettiRef.current.render();
      setTimeout(() => confettiRef.current.clear(), 2000);
      toast.success(`Correct! Earned ${points} points!`);
      if (completedExercises.length === 0 && !unlockedAchievements.includes('first_complete')) {
        unlockAchievement('first_complete');
      }
      if (hintsRevealed === 0 && !unlockedAchievements.includes('no_hints')) {
        unlockAchievement('no_hints');
      }
      if (completedExercises.length + 1 === exercises.length && !unlockedAchievements.includes('all_complete')) {
        unlockAchievement('all_complete');
      }
    } else if (!isCorrect) {
      toast.error('Not quite right. Keep trying!');
    }
  };

  const toggleFavorite = (id) => {
    setFavorites(prev => prev.includes(id) ? prev.filter(f => f !== id) : [...prev, id]);
    toast.success(favorites.includes(id) ? 'Removed from favorites' : 'Added to favorites!');
  };

  const handleRevealHint = () => {
    if (hintsRevealed < currentExercise.hints.length) {
      setHintsRevealed(prev => prev + 1);
      toast.success('New hint revealed!');
    } else {
      toast.error('No more hints available.');
    }
  };

  const handleExerciseChange = (index) => {
    saveCurrentState();
    setCurrentExerciseIndex(index);
  };

  const ExerciseList = () => {
    return (
      <div className={styles.exerciseList}>
        <div className={styles.exerciseNavigation}>
          <button 
            className={styles.navButton} 
            onClick={() => currentExerciseIndex > 0 && handleExerciseChange(currentExerciseIndex - 1)}
            disabled={currentExerciseIndex === 0}
          >
            <ChevronLeft size={20} />
          </button>
          <div className={styles.taskProgress}>
            <span>Task {currentExerciseIndex + 1} of {exercises.length}</span>
          </div>
          <button 
            className={styles.navButton} 
            onClick={() => currentExerciseIndex < exercises.length - 1 && handleExerciseChange(currentExerciseIndex + 1)}
            disabled={currentExerciseIndex === exercises.length - 1}
          >
            <ChevronRight size={20} />
          </button>
        </div>
      </div>
    );
  };

  // Function to highlight specific text in instructions
  const highlightText = (text) => {
    if (currentExercise.id === 1) {
      return text.replace(
        /'Hello Developer'/g, 
        "<span class='" + styles.highlightedText + "'>Hello Developer</span>"
      );
    } else if (currentExercise.id === 2) {
      return text.replace(
        /'Welcome to Web Development'/g, 
        "<span class='" + styles.highlightedText + "'>Welcome to Web Development</span>"
      );
    }
    return text;
  };

  return (
    <div className={styles.haContainer} data-theme={theme}>
      <canvas ref={canvasRef} className={styles.confettiCanvas}></canvas>
      <Toaster position="top-right" />

      <div className={styles.topBar}>
        <div className={styles.topBarCenter}>
          <h1 className={styles.logo}>
            <Code2 className={styles.logoIcon} />
            Inelf Practice
          </h1>
        </div>
        <div className={styles.topBarActions}>
          <button 
            className={styles.achievementsButton} 
            onClick={() => setShowAchievements(!showAchievements)}
            title="View achievements"
          >
            <Award size={20} />
          </button>
          <button onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')} className={styles.themeToggle}>
            {theme === 'light' ? <Moon size={20} /> : <Sun size={20} />}
          </button>
        </div>
      </div>

      <div className={styles.mainContent}>
        <div className={styles.leftPanel}>
          <div className={styles.panelHeader}>
            <h2 className={styles.headerTitle}>
              <BookOpen size={20} />
              Practice Exercises
            </h2>
          </div>
          <ExerciseList />
          <div className={styles.instructions}>
            <div className={styles.instructionHeader}>
              <Code2 size={20} />
              <h3>{currentExercise.title}</h3>
            </div>
            <div className={styles.instructionContent}>
              <div className={styles.taskHighlight}>
                <div className={styles.highlightHeader}>
                  <Target size={18} />
                  <span>Task Instructions</span>
                </div>
                <div 
                  className={styles.highlightContent}
                  dangerouslySetInnerHTML={{ __html: highlightText(currentExercise.instructions) }}
                />
              </div>
              <div className={styles.codeExample}>
                <div className={styles.codeHeader}>
                  <span className={styles.codeLabel}><b>Example:</b></span>
                  <button 
                    className={styles.copyButton}
                    onClick={() => {
                      navigator.clipboard.writeText(currentExercise.expectedCode);
                      toast.success('Code copied!');
                    }}
                  >
                    <Copy size={14} />
                  </button>
                </div>
                <code>{currentExercise.expectedCode}</code>
              </div>
            </div>
          </div>
          
          <div className={styles.resourcesSection}>
            <div className={styles.resourcesHeader}>
              <BookOpen size={18} />
              <h3>Learning Resources</h3>
            </div>
            <div className={styles.resourcesList}>
              {currentExercise.resources.map((resource, index) => (
                <a
                  key={index}
                  href={resource.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.resourceLink}
                >
                  <resource.icon size={16} />
                  <span>{resource.title}</span>
                  <ExternalLink size={14} />
                </a>
              ))}
            </div>
          </div>

          <button className={styles.hintButton} onClick={handleRevealHint}>
            <Brain size={18} />
            <span>Need a Hint?</span>
            <span className={styles.hintCount}>
              {hintsRevealed}/{currentExercise.hints.length}
            </span>
          </button>

          {hintsRevealed > 0 && (
            <div className={styles.hintsContainer}>
              {currentExercise.hints.slice(0, hintsRevealed).map((hint, index) => (
                <div key={index} className={styles.hintItem}>
                  <Zap size={16} />
                  <span>{hint}</span>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className={`${styles.centerPanel} ${isCorrect ? styles.correct : ''}`}>
          <div className={styles.editorHeader}>
            <span><FileCode size={16} /> practice.html</span>
            <div className={styles.editorActions}>
              <button className={styles.actionBtn} onClick={handleReset}>
                <RotateCcw size={16} /> Reset
              </button>
              <button className={styles.actionBtn} onClick={handleShowAnswer}>
                <Eye size={16} /> Answer
              </button>
            </div>
          </div>
          <div className={styles.editorWrapper}>
            <CodeMirror
              value={currentCode}
              height="100%"
              theme={dracula}
              extensions={[html()]}
              onChange={handleCodeChange}
              onCreateEditor={(view) => setEditorView(view)}
              placeholder="Write your HTML code here..."
            />
          </div>
          <div className={styles.editorFooter}>
            <button className={styles.submitBtn} onClick={handleSubmit}>
              Submit Code <ArrowRight size={16} />
            </button>
          </div>
        </div>

        <div className={styles.rightPanel}>
          <div className={styles.resultSection}>
            <h3>Your Result</h3>
            <div className={styles.resultPreview} dangerouslySetInnerHTML={{ __html: currentCode }} />
          </div>
          <div className={styles.resultSection}>
            <h3>Expected Result</h3>
            <div className={styles.resultPreview} dangerouslySetInnerHTML={{ __html: currentExercise.expectedResult }} />
          </div>
        </div>
      </div>

      {submitted && (
        <div className={`${styles.feedbackModal} ${isCorrect ? styles.success : styles.error}`}>
          {isCorrect ? (
            <div className={styles.successMessage}>
              <h2>🎉 Correct Solution!</h2>
              <p>Well done! Ready for the next challenge?</p>
              <button className={styles.nextBtn} onClick={() => {
                if (currentExerciseIndex < exercises.length - 1) {
                  handleExerciseChange(currentExerciseIndex + 1);
                }
                setSubmitted(false);
              }}>
                Continue <ArrowRight size={16} />
              </button>
            </div>
          ) : (
            <div className={styles.errorMessage}>
              <h2>Needs Adjustment</h2>
              <p>Compare your code with the expected result</p>
              <button onClick={() => setSubmitted(false)}>Try Again</button>
            </div>
          )}
        </div>
      )}

      {showAchievements && (
        <div className={styles.modalOverlay} onClick={() => setShowAchievements(false)}>
          <div className={styles.modal} onClick={e => e.stopPropagation()}>
            <div className={styles.modalHeader}>
              <h2><Award size={20} /> Achievements</h2>
              <button className={styles.closeButton} onClick={() => setShowAchievements(false)}>×</button>
            </div>
            <div className={styles.modalContent}>
              <div className={styles.achievementsList}>
                {achievements.map((achievement) => {
                  const isUnlocked = unlockedAchievements.includes(achievement.id);
                  return (
                    <div 
                      key={achievement.id} 
                      className={`${styles.achievementItem} ${isUnlocked ? styles.unlocked : styles.locked}`}
                    >
                      <div className={styles.achievementIcon}>
                        <achievement.icon size={24} />
                      </div>
                      <div className={styles.achievementInfo}>
                        <h3>{achievement.title}</h3>
                        <p>{achievement.description}</p>
                      </div>
                      {isUnlocked ? (
                        <CheckCircle2 size={20} className={styles.achievementUnlocked} />
                      ) : (
                        <Lock size={20} className={styles.achievementLocked} />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Ha;