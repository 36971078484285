import React, { useState } from 'react';
import './Contact.css';


export default function ContactPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(formData);
  };

  return (
    <>
      <div className="contact_container">
        <div className="contact_form">
          <form onSubmit={handleSubmit}>
            <div className="contact_form-group">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </div>

            <div className="contact_form-row">
              <div className="contact_form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>

              <div className="contact_form-group">
                <label>Phone Number</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="contact_form-group">
              <label>Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
            </div>

            <button type="submit" className="contact_submit-button">
              Submit
            </button>
          </form>
        </div>

        <div className="contact_info">
          <h1>Convey Your <br/> Ideas to Us</h1>
          <p>
            Contact us for questions, technical <br/> assistance, or collaboration opportunities <br/>
            via the contact information provided.
          </p>

          <div className="contact_details">
            <div className="contact_item">
              <div className="contact_icon contact_phone-icon"></div>
              <span>+123-456-7890</span>
            </div>
            <div className="contact_item">
              <div className="contact_icon contact_email-icon"></div>
              <span>hello@inelf.com</span>
            </div>
          </div>

          <div className="contact_plus-pattern"></div>
        </div>
      </div>
    </>
  );
}
