import React, { useState, useEffect, useCallback, useRef } from "react";
import { X, ChevronRight } from "lucide-react";
import { RiSpeakAiLine } from "react-icons/ri";
import { MdOutlineLightMode } from "react-icons/md";
import "./H4.css";
import listImage from "../Assets/h411.png"; // Intro slide image for HTML Lists

const FEMALE_VOICES = [
  "Google UK English Female",
  "Microsoft Zira Desktop",
  "Samantha",
  "Karen",
  "Tessa",
  "Serena",
  "Melina",
  "Veena",
  "Allison",
  "Ava",
];

const SlideProgress = ({ current, total }) => (
  <div className="progress-container">
    <div
      className="progress-bar"
      style={{ width: `${((current + 1) / total) * 100}%` }}
    />
  </div>
);

// Updated CodeEditor that checks if code is a valid React element
const CodeEditor = ({ code, title }) => (
  <div className="code-editor">
    <div className="editor-header">
      <span>{title}</span>
      <div className="editor-buttons">
        <div className="editor-button red"></div>
        <div className="editor-button yellow"></div>
        <div className="editor-button green"></div>
      </div>
    </div>
    <div className="editor-content">
      {React.isValidElement(code) ? (
        code
      ) : (
        <pre className="code-text">
          <code>{code}</code>
        </pre>
      )}
    </div>
  </div>
);

const H4 = ({ onClose }) => {
  const content = [
    {
      type: "intro",
      title: "HTML Lists",
      description:
        "HTML lists help to organize information in a clear, easy-to-read format. In this lesson, we'll explore three types of lists: ordered, unordered, and description lists. These are like different flavors of ice cream - each with its own special taste!",
      image: `<img src=${listImage} alt="HTML Lists" class="lesson-image" />`,
    },
    {
      title: "Ordered List",
      description:
        "An ordered list (<ol>) is like a step-by-step recipe. It shows items in a specific sequence, using numbers or letters. Perfect for instructions or ranking items!",
      visual: (
        <>
          <div className="split-view reduced-code-height">
            <CodeEditor
              title="index.html"
              code={`<ol>
  <li>Wake up</li>
  <li>Brush teeth</li>
  <li>Eat breakfast</li>
</ol>`}
            />
            <CodeEditor
              title="Output"
              code={`1. Wake up
2. Brush teeth
3. Eat breakfast`}
            />
          </div>
          <div className="list-design-container">
          <div className="highlight-box">
              <strong>Pro Tip:</strong> Use the <code>start</code> attribute like{" "}
              <code>&lt;ol start="5"&gt;&lt;/ol&gt;</code> to begin numbering from a specific value!
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Unordered List",
      description:
        "An unordered list (<ul>) is like a shopping list. It groups related items without any particular order, using bullets instead of numbers. Great for listing features or options!",
      visual: (
        <>
          <div className="split-view reduced-code-height">
            <CodeEditor
              title="index.html"
              code={`<ul>
  <li>Apples</li>
  <li>Bananas</li>
  <li>Cherries</li>
</ul>`}
            />
            <CodeEditor
              title="Output"
              code={
                <div className="output-preview">
                  <ul>
                    <li>Apples</li>
                    <li>Bananas</li>
                    <li>Cherries</li>
                  </ul>
                </div>
              }
            />
          </div>
          <div className="list-design-container">
            <div className="highlight-box">
              <strong>Fun Fact:</strong> You can change bullet styles with CSS, like{" "}
              <code>list-style-type: square;</code>
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Description List",
      description:
        "Description lists use the <dl> tag along with <dt> (term) and <dd> (description) tags. They are useful for pairs of information like definitions or FAQ answers.",
      visual: (
        <>
          <div className="split-view reduced-code-height">
            <CodeEditor
              title="index.html"
              code={`<dl>
  <dt>HTML</dt>
  <dd>HyperText Markup Language</dd>
  <dt>CSS</dt>
  <dd>Cascading Style Sheets</dd>
</dl>`}
            />
            <CodeEditor
              title="Output"
              code={
                <div className="output-preview">
                  <dl>
                    <dt>HTML</dt>
                    <dd>HyperText Markup Language</dd>
                    <dt>CSS</dt>
                    <dd>Cascading Style Sheets</dd>
                  </dl>
                </div>
              }
            />
          </div>
          <div className="list-design-container">
            <div className="highlight-box">
              <strong>Remember:</strong> <code>&lt;dt&gt;</code> is for the term,{" "}
              <code>&lt;dd&gt;</code> is for the description
            </div>
          </div>
        </>
      ),
    },
    {
      type: "outro",
      title: "Great job!",
      description:
        "You've completed the lesson on HTML lists. Now let's put this knowledge into practice!",
      button: "Start Practical",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [showHint, setShowHint] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [synth, setSynth] = useState(null);
  const [voices, setVoices] = useState([]);

  const contentRef = useRef(content);

  useEffect(() => {
    if ("speechSynthesis" in window) {
      const synthInstance = window.speechSynthesis;
      setSynth(synthInstance);
      const loadVoices = () => {
        const voicesList = synthInstance.getVoices();
        setVoices(voicesList.filter((v) => v.lang.startsWith("en")));
      };
      synthInstance.onvoiceschanged = loadVoices;
      loadVoices();
    }
  }, []);

  const speakSlide = useCallback(() => {
    if (!synth) return;
    synth.cancel();
    const slide = contentRef.current[currentSlide];
    const text = `${slide.title}. ${slide.description}`;
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.rate = 1.05;
    utterance.pitch = 1.25;
    utterance.volume = 1;
    utterance.lang = "en-US";

    const femaleVoice = voices.find(
      (voice) =>
        FEMALE_VOICES.some((name) => voice.name.includes(name)) ||
        voice.gender === "female"
    );
    if (femaleVoice) {
      utterance.voice = femaleVoice;
      utterance.voiceURI = femaleVoice.voiceURI;
    }

    utterance.onstart = () => setIsSpeaking(true);
    utterance.onend = () => setIsSpeaking(false);
    utterance.onerror = () => setIsSpeaking(false);

    synth.speak(utterance);
  }, [synth, currentSlide, voices]);

  const toggleVoice = () => {
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      speakSlide();
    }
  };

  const handlePrevious = useCallback(
    () => setCurrentSlide((prev) => Math.max(0, prev - 1)),
    []
  );
  const handleNext = useCallback(() => {
    setCurrentSlide((prev) => Math.min(content.length - 1, prev + 1));
    setShowHint(false);
  }, [content.length]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === "ArrowRight") handleNext();
      if (e.key === "ArrowLeft") handlePrevious();
      if (e.key === "Enter" && currentSlide === content.length - 1) {
        alert("Let's start the practical exercise!");
      }
    },
    [currentSlide, handleNext, handlePrevious, content.length]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.speechSynthesis.cancel();
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const toggleDarkMode = () => {
    setIsDarkMode((prev) => !prev);
  };

  const renderSlideContent = () => {
    const slide = content[currentSlide];
    if (slide.type === "intro" || slide.type === "outro") {
      return (
        <div className="special-slide">
          <h1>{slide.title}</h1>
          <p>{slide.description}</p>
          {slide.image &&
            (typeof slide.image === "string" ? (
              <div dangerouslySetInnerHTML={{ __html: slide.image }} />
            ) : (
              slide.image
            ))}
          {slide.type === "outro" && (
            <button
              className="start-button"
              onClick={() =>
                alert("Let's start the practical exercise!")
              }
            >
              {slide.button}
            </button>
          )}
        </div>
      );
    }
    return (
      <div className="regular-slide">
        <div className="slide-header">
          <h2>{slide.title}</h2>
          <p>{slide.description}</p>
        </div>
        {slide.visual}
      </div>
    );
  };

  return (
    <div className={`slides-container ${isDarkMode ? "dark-mode" : ""}`}>
      <SlideProgress current={currentSlide} total={content.length} />

      <button
        className="close-button"
        onClick={() => {
          window.speechSynthesis.cancel();
          onClose();
        }}
      >
        <X size={24} />
      </button>

      <button
        className={`voice-btn ${isSpeaking ? "speaking" : ""}`}
        onClick={toggleVoice}
      >
        <div className="voice-container">
          <RiSpeakAiLine size={24} />
          {isSpeaking && (
            <div className="sound-waves">
              {[...Array(3)].map((_, i) => (
                <span
                  key={i}
                  className="wave"
                  style={{ animationDelay: `${i * 0.2}s` }}
                />
              ))}
            </div>
          )}
        </div>
      </button>

      <button
        className="dark-mode-button"
        onClick={toggleDarkMode}
        title="Toggle Dark Mode"
      >
        <MdOutlineLightMode
          size={24}
          color={isDarkMode ? "#fbbf24" : "#2d3748"}
        />
      </button>

      {renderSlideContent()}

      {showHint && currentSlide === 0 && (
        <div className="hint-message">
          Click on the arrow (or press the → key)
          <ChevronRight className="chevron" size={20} />
        </div>
      )}

      <div className="slide-navigation">
        <button
          className="nav-arrow prev"
          onClick={handlePrevious}
          disabled={currentSlide === 0}
        >
          ◀
        </button>
        <button
          className="nav-arrow next"
          onClick={handleNext}
          disabled={currentSlide === content.length - 1}
        >
          ▶
        </button>
      </div>
    </div>
  );
};

export default H4;
