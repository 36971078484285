import React from 'react';
import './Business.css';
import BusinessImage from '../../assets/images/Businessimg.png';
import Pslide from './Pslide';
import C from "./Connect.jsx";
import Animated from "./Animated.jsx";
import Footer from '../../components/Footer/Footer';

const BusinessBanner = () => {
  return (
    <>
      <div className="business-banner">
        <div className="business-banner-text">
          <h1>Innovate and Succeed <br />
          with inelf <br />
          "Your Partner in Growth"</h1>
        </div>
        <div className="business-banner-image">
          <img src={BusinessImage} alt="Business" />
        </div>
      </div>
      <div style={{ marginTop: "100px" }}></div>
      <Pslide />
      <div style={{ marginTop: "100px" }}></div>
      <div>
            <h1 className="mission_main_heading">Why Partner with Us?</h1>
            <p style={{textAlign:'center',width:'80%',margin:'auto', fontSize: '21px'}}className="mission_text">Empower success through tailored solutions,<br /> Prioritizes student success, and excels in solving challenges.</p>
      </div>
      <Animated /> 
      <C />
      <Footer />
    </>
  );
};

export default BusinessBanner;
