import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "./SliderCareer.css"

import image1 from '../../assets/images/slider4.png';
import image2 from '../../assets/images/slider5.png';
import image3 from '../../assets/images/slider6.png';

const Slider = () => {
  const category2 = [
    {
      heading: 'Transparent Structure',
      description: 'We believe in flat hierarchies. This means quick communication, swift decision-making, and open feedback. Expect rapid career growth and a supportive, joke-filled environment.',
      image: image1,
    },
    {
      heading: 'Great team spirit',
      description: 'Join a motivated and ambitious team with strong unity! Experience daily collaboration and unwavering support, with exciting events and activities to foster camaraderie.',
      image: image2,
    },
    {
      heading: 'Responsibility',
      description: 'At inelf, you make an immediate and positive impact every single day. Your efforts help students succeed in their studies and support companies in finding new talent.',
      image: image3,
    },
  ];

  return (
    <>
      <div className="pp2">
        <h1 className="hh2" style={{margin:"0px"}}>What Makes Us Special </h1>
        <p className="hhh2">We put our <b>heart and soul</b> into every detail–<br/>so that you learn fast and easily by making <b>complex topics simple.</b></p>
      </div>

      <div className="poster2 custom-carousel-container">
        <Carousel
          showThumbs={false}
          autoPlay={true}
          transitionTime={3}
          infiniteLoop={true}
          showStatus={false}
          showIndicators={true}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            const defStyle = { 
              width: 10,   // Adjust the width of the dot
              height: 10,  // Adjust the height of the dot
              marginLeft: 3, 
              marginRight: 3, 
              cursor: "pointer",
              display: 'inline-block', // Ensure dots are displayed inline
              borderRadius: '50%',     // Make the dots circular
              backgroundColor: isSelected ? '#000' : '#ccc', // Change color based on selection
              border: 'none',  // Ensure no border around the dot
            };
            return (
              <span
                style={defStyle}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                tabIndex={0}
                aria-label={`Indicator ${index + 1}`}
              />
            );
          }}
        >
          {category2.map((item, index) => (
            <div key={index} className="slider-container2">
              <div className="slider-content2">
                <h1 className="heading2" style={{ fontSize: '32px' }}>{item.heading}</h1>
                <p className="description2" style={{ fontSize: '19px' }}>{item.description}</p>
              </div>
              <div className="image-container2">
                <img
                  src={item.image}
                  alt={item.heading}
                  className="slider-image2"
                />
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default Slider;
