import React from "react";
import "./Connect.css";

const Connect = () => {
  return (
    <div className="connect-container">
      <div className="content">
        <h1 className="heading">
          Let’s <span className="highlight">Collaborate</span>
        </h1>
        <h2 className="subheading">Interested in Partnering?</h2>
        <p className="description">
          "Discover how we can work together to create something amazing. Whether it’s a business inquiry or a partnership proposal, we’re eager to hear from you." 
        </p>
        <a href="/contact">
        <button className="connect-button">Connect  Now</button>
        </a>
      </div>
    </div>
  );
};

export default Connect;
