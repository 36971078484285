import React, { useEffect } from 'react';
import './Animated.css';

const Animated = () => {
  const data = [
    {
      title: 'Leadership',
      description: 'Fully committed to the success company.',
      icon: '🎖️',
    },
    {
      title: 'Responsibility',
      description: 'Students will always be our top priority.',
      icon: '🎓',
    },
    {
      title: 'Flexibility',
      description: 'The ability to switch is an important skill.',
      icon: '💎',
    },
    {
      title: 'Solve the Problem',
      description: 'Code your way to a solution.',
      icon: '💻',
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll('.animated-content');
      elements.forEach(element => {
        const rect = element.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          element.classList.add('visible');
        } else {
          element.classList.remove('visible');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="animated-container">
      {data.map((item, index) => (
        <div key={index} className="animated-section">
          {index % 2 === 0 ? (
            <>
              <div className="animated-content left">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
              <div className="animated-icon-container" style={index === 3 ? { top: '-20px' } : {}}>
                <div className="animated-icon">{item.icon}</div>
                {index !== data.length - 1 && <div className="divider"></div>}
              </div>
            </>
          ) : (
            <>
              <div className="animated-icon-container" style={index === 3 ? { top: '10px' } : {}}>
                <div className="animated-icon">{item.icon}</div>
                {index !== data.length - 1 && <div className="divider"></div>}
              </div>
              <div className="animated-content right">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default Animated;
