import React from 'react';
import s from './Pool.module.css'; // Import CSS module
import p1 from "../../assets/images/p1.png";
import p2 from "../../assets/images/p22.png";

export default function Part() {
  return (
    <div className={s.learningPlatform}>
      <section className={s.learningExperience}>
        <div className={s.content} style={{ marginLeft: "64px" }}>
          <div className={s.blueRectangle}></div>
          <h2>Employer Branding & Recruiting</h2>
          <p>On Inelf, you can connect with junior talent at every stage of their career journey—from learning and career exploration to job placement.</p>
          <ul>
            <li>Build your brand among the next generation of talent.</li>
            <li>Reach aspiring professionals during their career growth.</li>
            <li>Attract motivated candidates ready to make an impact. </li>
          </ul>
        </div>
        <div className={s.image}>
          <img src={p1} alt="Person working on computer" />
        </div>
      </section>

      <section className={s.boostEmployability}>
        <div className={s.image}>
          <img src={p2} alt="Magnifying glass on profile" />
        </div>
        <div className={s.content} style={{ marginLeft: "57px" }}>
          <h2>Unlock Future Potential</h2>
          <p>At Inelf, we connect companies with tomorrow's workforce. By bridging the gap between education and employment, we help you find <i>skilled talent</i></p>
          <ul>
            <li>Partner with a platform that nurtures real-world skills.</li>
            <li>Discover a curated pool of engaged, motivated learners.</li>
            <li>Shape the future by empowering and hiring junior talent.</li>
          </ul>
        </div>
      </section>
    </div>
  );
}
