import React from 'react';
import './About.css';
import GraduationImage from '../../assets/images/Aboutimage.png';
import OurValues from '../../components/OurValues/OurValues';
import Footer from '../../components/Footer/Footer';
import Slider from './SliderAbout.jsx';
import Mission from '../../components/Mission/Mission';
import Features from './Features.js';

const AboutBanner = () => {
  return (
    <>
    <div className="about_banner">
      <div className="about_banner-text">
        <h1>Empowering the world <br/>
        to excel at their <br/>
        Studies</h1>
      </div>
      <div className="about_banner-image">
        <img src={GraduationImage} alt="Graduation celebration" />
      </div>
    </div>
    <Mission/>
    <OurValues/>
    <div style={{marginTop:"100px"}}></div>
    <Slider/>
    <Features/>
    <div style={{marginTop:"100px"}}></div>
    <Footer/>
    </>
  );
};

export default AboutBanner;
