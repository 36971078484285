import React from "react";
import "./Cu.css";

const Connect = () => {
  return (
    <div className="connect-container">
      <div className="content">
        <h1 className="heading">
        Discover Skilled Talent
        </h1>
        <h2 className="subheading">In Search of Excellence?</h2>
        <p className="description">
        "We provide the insights and assistance you need to hire the best talent. Let's work together to build your dream team."
        </p>
        <a href="/contact">
        <button className="connect-button">Get in Touch</button>
        </a>
      </div>
    </div>
  );
};

export default Connect;
