import React, { useState, useEffect, useCallback } from "react";
import { X, ChevronRight } from "lucide-react";
import { RiSpeakAiLine } from "react-icons/ri";
import { MdOutlineLightMode } from "react-icons/md";
import "./H3.css";
import hi from "../Assets/h31.png"; // Intro slide image

const FEMALE_VOICES = [
  "Google UK English Female",
  "Microsoft Zira Desktop",
  "Samantha",
  "Karen",
  "Tessa",
  "Serena",
  "Melina",
  "Veena",
  "Allison",
  "Ava",
];

const SlideProgress = ({ current, total }) => (
  <div className="progress-container">
    <div className="progress-bar" style={{ width: `${((current + 1) / total) * 100}%` }} />
  </div>
);

const CodeEditor = ({ code, title }) => (
  <div className="code-editor">
    <div className="editor-header">
      <span>{title}</span>
      <div className="editor-buttons">
        <div className="editor-button red"></div>
        <div className="editor-button yellow"></div>
        <div className="editor-button green"></div>
      </div>
    </div>
    <div className="editor-content">
      <pre className="code-text">
        <code>{code}</code>
      </pre>
    </div>
  </div>
);

const H3 = ({ onClose }) => {
  const content = [
    {
      type: "intro",
      title: "Links and Images",
      description:
        "In this lesson, we explore two important parts of HTML: links and images. Links let you jump from one page to another like a magic doorway, and images make your page come alive with pictures.",
      image: `<img src=${hi} alt="Links and Images" class="lesson-image" />`,
    },
    {
      title: "Link tag",
      description:
        "The link tag, or anchor tag (<a>), creates clickable text or buttons that take you to other web pages or sites. Think of it like a treasure map that guides you to a secret place!",
      visual: (
        <>
          <div className="split-view reduced-code-height">
            <CodeEditor
              title="index.html"
              code={`<a href="https://www.example.com">
  Visit Example
</a>`}
            />
            <CodeEditor
              title="Output"
              code={
                <div className="output-preview">
                  <a
                    href="https://www.example.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visit Example
                  </a>
                </div>
              }
            />
          </div>
          <div className="link-design-container">
            <div className="highlight-box">
              The text surrounded by the &lt;a&gt; &lt;/a&gt; tag becomes a link
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Image tag",
      description:
        "The image tag (<img>) shows pictures on your page. It does not have a closing tag, and you add details with attributes like src and alt. Images make your work colorful and interesting!",
      visual: (
        <>
          <div className="split-view reduced-code-height">
            <CodeEditor
              title="index.html"
              code={`<img src="https://picsum.photos/150" />`}
            />
            <CodeEditor
              title="Output"
              code={
                <div className="output-preview">
                  <img src="https://picsum.photos/150" />
                </div>
              }
            />
          </div>
          <div className="link-design-container">
            <div className="highlight-box">
              <strong>Note that</strong> &lt;img&gt; tags don't require closing tags as they don't enclose any text.
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Attributes",
      description:
        "Attributes are like extra clues that give more information about an element. For links, the href attribute tells where to go. For images, attributes like src and alt describe the picture. They help browsers understand your code better.",
      visual: (
        <div className="attribute-showcase">
          <h3>Common Attributes for Links and Images:</h3>
          <div className="attribute-grid">
            <div className="attribute-item">
              <h4>href</h4>
              <p>Specifies the URL of the page the link goes to</p>
              <code>&lt;a href="https://www.example.com"&gt;</code>
            </div>
            <div className="attribute-item">
              <h4>target</h4>
              <p>Specifies where to open the linked document</p>
              <code>&lt;a target="_blank"&gt;</code>
            </div>
            <div className="attribute-item">
              <h4>src</h4>
              <p>Specifies the path to the image</p>
              <code>&lt;img src="image.jpg"&gt;</code>
            </div>
            <div className="attribute-item">
              <h4>alt</h4>
              <p>Provides alternative text for an image</p>
              <code>&lt;img alt="Description"&gt;</code>
            </div>
          </div>
        </div>
      ),
    },
    {
      type: "outro",
      title: "Great job!",
      description:
        "You've completed the lesson on HTML link and image tags. Now let's put this knowledge into practice!",
      button: "Start Practical",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [showHint, setShowHint] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [synth, setSynth] = useState(null);
  const [voices, setVoices] = useState([]);

  useEffect(() => {
    if ("speechSynthesis" in window) {
      const synthInstance = window.speechSynthesis;
      setSynth(synthInstance);
      const loadVoices = () => {
        const voicesList = synthInstance.getVoices();
        setVoices(voicesList.filter((v) => v.lang.startsWith("en")));
      };
      synthInstance.onvoiceschanged = loadVoices;
      loadVoices();
    }
  }, []);

  const speakSlide = useCallback(() => {
    if (!synth) return;
    synth.cancel();
    const slide = content[currentSlide];
    const text = `${slide.title}. ${slide.description}`;
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.rate = 1.05;
    utterance.pitch = 1.25;
    utterance.volume = 1;
    utterance.lang = "en-US";

    const femaleVoice = voices.find(
      (voice) =>
        FEMALE_VOICES.some((name) => voice.name.includes(name)) ||
        voice.gender === "female"
    );
    if (femaleVoice) {
      utterance.voice = femaleVoice;
      utterance.voiceURI = femaleVoice.voiceURI;
    }

    utterance.onstart = () => setIsSpeaking(true);
    utterance.onend = () => setIsSpeaking(false);
    utterance.onerror = () => setIsSpeaking(false);

    synth.speak(utterance);
  }, [synth, currentSlide, content, voices]);

  const toggleVoice = () => {
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      speakSlide();
    }
  };

  const handlePrevious = useCallback(() => setCurrentSlide((prev) => Math.max(0, prev - 1)), []);
  const handleNext = useCallback(() => {
    setCurrentSlide((prev) => Math.min(content.length - 1, prev + 1));
    setShowHint(false);
  }, [content.length]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === "ArrowRight") handleNext();
      if (e.key === "ArrowLeft") handlePrevious();
      if (e.key === "Enter" && currentSlide === content.length - 1) {
        alert("Let's start the practical exercise!");
      }
    },
    [currentSlide, handleNext, handlePrevious, content.length]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.speechSynthesis.cancel();
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const toggleDarkMode = () => {
    setIsDarkMode((prev) => !prev);
  };

  const renderSlideContent = () => {
    const slide = content[currentSlide];
    if (slide.type === "intro" || slide.type === "outro") {
      return (
        <div className="special-slide">
          <h1>{slide.title}</h1>
          <p>{slide.description}</p>
          {slide.image &&
            (typeof slide.image === "string" ? (
              <div dangerouslySetInnerHTML={{ __html: slide.image }} />
            ) : (
              slide.image
            ))}
          {slide.type === "outro" && (
            <button
              className="start-button"
              onClick={() => alert("Let's start the practical exercise!")}
            >
              {slide.button}
            </button>
          )}
        </div>
      );
    }
    return (
      <div className="regular-slide">
        <div className="slide-header">
          <h2>{slide.title}</h2>
          <p>{slide.description}</p>
        </div>
        {slide.visual}
      </div>
    );
  };

  return (
    <div className={`slides-container ${isDarkMode ? "dark-mode" : ""}`}>
      <SlideProgress current={currentSlide} total={content.length} />

      <button
        className="close-button"
        onClick={() => {
          window.speechSynthesis.cancel();
          onClose();
        }}
      >
        <X size={24} />
      </button>

      <button className={`voice-btn ${isSpeaking ? "speaking" : ""}`} onClick={toggleVoice}>
        <div className="voice-container">
          <RiSpeakAiLine size={24} />
          {isSpeaking && (
            <div className="sound-waves">
              {[...Array(3)].map((_, i) => (
                <span key={i} className="wave" style={{ animationDelay: `${i * 0.2}s` }} />
              ))}
            </div>
          )}
        </div>
      </button>

      <button className="dark-mode-button" onClick={toggleDarkMode} title="Toggle Dark Mode">
        <MdOutlineLightMode size={24} color={isDarkMode ? "#fbbf24" : "#2d3748"} />
      </button>

      {renderSlideContent()}

      {showHint && currentSlide === 0 && (
        <div className="hint-message">
          Click on the arrow (or press the → key)
          <ChevronRight className="chevron" size={20} />
        </div>
      )}

      <div className="slide-navigation">
        <button className="nav-arrow prev" onClick={handlePrevious} disabled={currentSlide === 0}>
          ◀
        </button>
        <button
          className="nav-arrow next"
          onClick={handleNext}
          disabled={currentSlide === content.length - 1}
        >
          ▶
        </button>
      </div>
    </div>
  );
};

export default H3;
