import React from 'react';
import './Careers.css';
import CareerImage from '../../assets/images/Careerimg.png';
import Footer from '../../components/Footer/Footer';
import P from './Part.jsx'
import SliderC from './SliderCareer.jsx'
import LeftFlower from '../../assets/images/flower1.png';
import RightFlower from '../../assets/images/flower2.png';

const CareerBanner = () => {
  return (
    <>
      <div className="career-banner">
        <div className="career-banner-text">
          <h1>Join Us in Shaping <br />
          the Future of <br />
          Learning & Innovation</h1>
        </div>
        <div className="career-banner-image">
          <img src={CareerImage} alt="Jobs" />
        </div>
      </div>
      <div style={{ marginTop: "100px" }}></div>
      <div>
            <h1 className="mission_main_heading">Become part of our team</h1>
            <p style={{textAlign:'center',width:'80%',margin:'auto', fontSize: '21px'}}className="mission_text">Join us in making quality education accessible to all.<br />"Together, we can bridge the skills gap and empower future generations."</p>
      </div>
      <P />
      <SliderC />
      <div style={{ marginTop: "90px" }}></div>
<div className="words_container">
  <div className="words_flower words_flower-left">
    <img style={{ width: "240px" }} src={LeftFlower} alt="" />
  </div>
  <div className="words_content">
    <h2 className="words_heading">Be <span className="border_design">inspired</span></h2>
    <br />
    <p className="words_text"><b>Need some more time to think it over?</b></p>
    <br />
    <p className="words_text">
      Join our talent community to stay in touch with the inelf team, 
      keep up to date with relevant opportunities, and receive tips 
      to support your job hunt.
    </p>
    <button className="words_button">Join Now</button>
  </div>
  <div className="words_flower words_flower-right">
    <img src={RightFlower} alt="" />
  </div>
</div>

<div style={{marginTop:"150px"}}></div>

      <Footer />
    </>
  );
};

export default CareerBanner;
