import React, { useState, useEffect } from 'react';
import { X, ChevronRight } from 'lucide-react';
import { RiSpeakAiLine } from "react-icons/ri";
import { MdOutlineLightMode } from "react-icons/md";
import '../HSlides/H1.css';
import hi from "../Assets/h11.png";
import pro1 from "../Assets/pro1.png";
import pro2 from "../Assets/pro2.png";
import pro4 from "../Assets/pro4.png";
import pro5 from "../Assets/pro5.png";

// Preferred female voices list
const FEMALE_VOICES = [
  'Google UK English Female',
  'Microsoft Zira Desktop',
  'Samantha',
  'Karen',
  'Tessa',
  'Serena',
  'Melina',
  'Veena',
  'Allison',
  'Ava'
];

const SlideProgress = ({ current, total }) => (
  <div className="progress-container">
    <div className="progress-bar" style={{ width: `${((current + 1) / total) * 100}%` }} />
  </div>
);

const H1 = ({ onClose }) => {
  const content = [
    {
      type: 'intro',
      title: "Let's study HTML & CSS",
      description:
        "Welcome to the exciting world of HTML & CSS! In this lesson, we're going to create a website step by step. No prior experience is needed—just follow along, and you'll learn by doing. By the end, you'll have built a website from scratch!",
      image: `<img src=${hi} alt="HTML & CSS lesson image" class="lesson-image" />`
    },
    {
      type: 'overview',
      title: "What are HTML & CSS?",
      description:
        "HTML & CSS are languages used to build and design the appearances of websites. HTML defines the structure of a webpage, while CSS styles it. Think of HTML as the skeleton of a house and CSS as the paint and decor.",
      visual: (
        <div className="split-visual">
          <div className="visual-section">
            <img src={pro1} alt="HTML Structure" className="structure-image" />
            <div className="highlight-box">
              HTML = House Structure ➔ Walls, Doors, Rooms
            </div>
          </div>
          <div className="visual-section">
            <img src={pro2} alt="CSS Styling" className="structure-image" />
            <div className="highlight-box">
              CSS = Paint & Decor ➔ Colors, Furniture Style
            </div>
          </div>
        </div>
      )
    },
    {
      title: "How HTML Works",
      description:
        "HTML uses tags to structure content. Tags are like labels that tell the browser how to display text. For example, wrap text in <h1> for a heading or <a> for a link.",
      visual: (
        <div className="split-visual">
          <div className="visual-section">
            <img src={pro4} alt="HTML Code Example" className="reduced-image" />
          </div>
          <div className="visual-section">
            <img src={pro5} alt="HTML Example" className="reduced-image" />
          </div>
        </div>
      )
    },
    {
      title: "Opening and Closing Tags",
      description:
        "HTML elements are like a perfectly crafted sandwich—every tag needs a start and an end. Just as you begin your sandwich with a slice of bread, fill it with tasty content, and complete it with another slice, HTML follows the same rule. The opening tag kicks off the element, the content fills it with meaning, and the closing tag seals it all together.",
      visual: (
        <div className="tag-example">
          <p><b>Pro Tip:</b> <i>Don't forget the "/" as it is like a STOP sign!</i></p>
          <div className="code-block">
            <p><span className="code-key">Opening:</span> &lt;bread&gt;</p>
            <p><span className="code-key">Filling:</span> 🥪 Cheese</p>
            <p><span className="code-key">Closing:</span> &lt;/bread&gt;</p>
          </div>
        </div>
      )
    },
    {
      type: 'outro',
      title: "Great job!",
      description:
        "You've completed the introduction to HTML & CSS. Now let's do some practical work!",
      button: "Start Practical"
    }
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [showHint, setShowHint] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [synth, setSynth] = useState(null);
  const [voices, setVoices] = useState([]);

  // Setup speech synthesis and load voices
  useEffect(() => {
    if ('speechSynthesis' in window) {
      const synthInstance = window.speechSynthesis;
      setSynth(synthInstance);
      const loadVoices = () => {
        const voicesList = synthInstance.getVoices();
        setVoices(voicesList.filter(v => v.lang.startsWith('en')));
      };
      synthInstance.onvoiceschanged = loadVoices;
      loadVoices();
    }
  }, []);

  const speakSlide = () => {
    if (!synth) return;
    // Cancel any ongoing speech
    synth.cancel();

    const slide = content[currentSlide];
    const text = `${slide.title}. ${slide.description}`;
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.rate = 1.05;
    utterance.pitch = 1.25;
    utterance.volume = 1;
    utterance.lang = 'en-US';

    // Select a preferred female voice if available
    const femaleVoice = voices.find(voice =>
      FEMALE_VOICES.some(name => voice.name.includes(name)) || voice.gender === 'female'
    );
    if (femaleVoice) {
      utterance.voice = femaleVoice;
      utterance.voiceURI = femaleVoice.voiceURI;
    }

    utterance.onstart = () => setIsSpeaking(true);
    utterance.onend = () => setIsSpeaking(false);
    utterance.onerror = () => setIsSpeaking(false);

    synth.speak(utterance);
  };

  // Toggle voice: if speaking, cancel; otherwise, start speaking
  const toggleVoice = () => {
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
    } else {
      speakSlide();
    }
  };

  const handlePrevious = () => setCurrentSlide(prev => Math.max(0, prev - 1));
  const handleNext = () => {
    setCurrentSlide(prev => Math.min(content.length - 1, prev + 1));
    setShowHint(false);
  };

  // Fixed key press handler to check for the "Enter" key on the final slide
  const handleKeyPress = (e) => {
    if (e.key === 'ArrowRight') handleNext();
    if (e.key === 'ArrowLeft') handlePrevious();
    if (e.key === 'Enter' && currentSlide === content.length - 1) {
      window.location.href = '/HTML/Hcode1';
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.speechSynthesis.cancel();
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [currentSlide]);

  const toggleDarkMode = () => {
    setIsDarkMode(prev => !prev);
  };

  const renderSlideContent = () => {
    const slide = content[currentSlide];
    if (slide.type === 'intro' || slide.type === 'outro') {
      return (
        <div className="special-slide">
          <h1>{slide.title}</h1>
          <p>{slide.description}</p>
          {slide.image && (typeof slide.image === 'string' ? (
            <div dangerouslySetInnerHTML={{ __html: slide.image }} />
          ) : slide.image)}
          {slide.type === 'outro' && (
            <button 
              className="start-button" 
              onClick={() => window.location.href = '/HTML/Hcode1'}
            >
              {slide.button}
            </button>
          )}
        </div>
      );
    }
    return (
      <div className="regular-slide">
        <div className="slide-header">
          <h2>{slide.title}</h2>
          <p>{slide.description}</p>
        </div>
        {slide.visual}
      </div>
    );
  };

  return (
    <div className={`slides-container ${isDarkMode ? "dark-mode" : ""}`}>
      <SlideProgress current={currentSlide} total={content.length} />
      
      <button className="close-button" onClick={() => {
        window.speechSynthesis.cancel();
        onClose();
      }}>
        <X size={24} />
      </button>

      {/* Voice Assistant Button */}
      <button 
        className={`voice-btn ${isSpeaking ? 'speaking' : ''}`}
        onClick={toggleVoice}
      >
        <div className="voice-container">
          <RiSpeakAiLine size={24} />
          {isSpeaking && (
            <div className="sound-waves">
              {[...Array(3)].map((_, i) => (
                <span 
                  key={i} 
                  className="wave" 
                  style={{ animationDelay: `${i * 0.2}s` }}
                />
              ))}
            </div>
          )}
        </div>
      </button>

      {/* Dark Mode Toggle Button */}
      <button
        className="dark-mode-button"
        onClick={toggleDarkMode}
        title="Toggle Dark Mode"
      >
        <MdOutlineLightMode size={24} color={isDarkMode ? '#fbbf24' : '#2d3748'} />
      </button>

      {renderSlideContent()}

      {showHint && currentSlide === 0 && (
        <div className="hint-message">
          Click on the arrow (or press the → key) 
          <ChevronRight className="chevron" size={20} />
        </div>
      )}

      <div className="slide-navigation">
        <button className="nav-arrow prev" onClick={handlePrevious} disabled={currentSlide === 0}>
          ◀
        </button>
        <button className="nav-arrow next" onClick={handleNext} disabled={currentSlide === content.length - 1}>
          ▶
        </button>
      </div>
    </div>
  );
};

export default H1;
