import React from 'react';
import './LoginModal.css';
import { FaEnvelope, FaRegTimesCircle} from 'react-icons/fa';
import { IoIosCall } from "react-icons/io";
import googleIcon from '../../assets/icons/google.png';
import facebookIcon from '../../assets/icons/facebooki.png';
import LoginImage from '../../assets/images/Loginimg.png';

const LoginModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <div className="modal-left">
          <button className="close-button" onClick={onClose}>
            <FaRegTimesCircle size={24} />
          </button>
          <h2 className="mo">Log in or sign up in seconds</h2>
          <p className="mo">
            Build skills for today, tomorrow, and beyond. (it’s free)!{' '}
            <span style={{ color: '#47a5c5', fontFamily: '"Edu SA Beginner", cursive' }}>
              Education to future-proof your career.
            </span>
          </p>

          <div className="login-options">
            <button className="login-option">
              <img src={googleIcon} alt="Google" className="login-icon" /> Continue with Google
            </button>
            <button className="login-option">
              <img src={facebookIcon} alt="Facebook" className="login-icon" /> Continue with Facebook
            </button>
            <button className="login-option">
              <FaEnvelope className="login-icon" /> Continue with email
            </button>
            <button className="login-option">
              <IoIosCall className="login-icon" /> Login with mobile
            </button>
          </div>
          <p className="terms">
            By continuing, you agree to inelf <a href="#terms">Terms of Use</a> and <a href="#privacy">Privacy Policy</a>.
          </p>
        </div>
        <div className="modal-right">
          <img src={LoginImage} alt="Login" />
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
