import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import HeroSection from './pages/Home/HeroSection';
import About from './pages/About/About';
import Careers from './pages/Careers/Careers';
import Business from './pages/Business/Business';
import Company from './pages/Companies/Companies';
import LoginModal from './components/LoginModal/LoginModal';
import Contact from './pages/Contact/Contact';
import Main from './Programming/Html and css/HLayout';
import Editor from './Programming/Html and css/HEditor/Ha';
import LayoutWithHeader from './layouts/LayoutWithHeader';

function App() {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Router>
      <Routes>
        {/* Routes with Header */}
        <Route element={<LayoutWithHeader openModal={openModal} />}>
          <Route path="/" element={<HeroSection />} />
          <Route path="/about" element={<About />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/Companies" element={<Company />} />
          <Route path="/business" element={<Business />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Courses/HTML" element={<Main />} />
        </Route>

        {/* Route without Header */}
        <Route path="/HTML/Hcode1" element={<Editor />} />

        <Route path="*" element={<h1>Not found</h1>} />
      </Routes>

      <LoginModal isOpen={isModalOpen} onClose={closeModal} />
    </Router>
  );
}

export default App;
