import React from 'react';
import './OurValues.css';
import APlusImage from '../../assets/images/v1.png'; // Replace with the actual path of the image
import HeartImage from '../../assets/images/v2.png'; // Replace with the actual path of the image
import GraphImage from '../../assets/images/v3.png'; // Replace with the actual path of the image
import ThumbsUpImage from '../../assets/images/v4.png'; // Replace with the actual path of the image
import LightBulbImage from '../../assets/images/v5.png'; // Replace with the actual path of the image

const OurValues = () => {
  return (
    <>
     <div>
            <h1 style={{marginBottom:"10px",marginTop:"30px"}}className="mission_main_heading">Our Values</h1>
        </div>
    <div className="values-section">
      <div className="value-card">
        <img src={APlusImage} alt="A+ Icon" className="value-icon" />
        <h3>Be An A+ Student</h3>
        <p>We believe in sharing knowledge, continuously learning and challenging ourselves.</p>
      </div>
      <div className="value-card">
        <img src={HeartImage} alt="Heart Icon" className="value-icon" />
        <h3>Our Caring Community</h3>
        <p>As a family we express ourselves, have fun, embrace feedback, and care about each other.</p>
      </div>
      <div className="value-card">
        <img src={GraphImage} alt="Graph Icon" className="value-icon" />
        <h3>Efficiency + Data = Success</h3>
        <p>* Our formula for decision making</p>
      </div>
      <div className="value-card">
        <img src={ThumbsUpImage} alt="Thumbs Up Icon" className="value-icon" />
        <h3>Team Make the Dream Work</h3>
        <p>Transparent and direct communication create a great team culture.</p>
      </div>
      <div className="value-card">
        <img src={LightBulbImage} alt="Light Bulb Icon" className="value-icon" />
        <h3>Powering Innovation</h3>
        <p>Our creative and energetic mindset drives innovation.</p>
      </div>
    </div>
    </>
  );
};

export default OurValues;