import React from 'react';
import s from './Part.module.css'; // Import CSS module
import wwa from "../../assets/images/w1.png";
import wwo from "../../assets/images/w2.png";

export default function Part() {
  return (
    <div className={s.learningPlatform}>
      <section className={s.learningExperience}>
        <div className={s.content} style={{ marginLeft: "64px" }}>
          <div className={s.blueRectangle}></div>
          <h2>Who We Are</h2>
          <p>Inelf is a team of passionate individuals from around the world. we are united by a shared mission to transform the learning journey for <i>millions of students globally.</i></p>
          <ul>
            <li>A team dedicated to improving education for millions.</li>
            <li>A collaborative and creative workspace fostering innovation.</li>
            <li>A culture of connection, growth, and impact.</li>
          </ul>
        </div>
        <div className={s.image}>
          <img src={wwa} alt="Person working on computer" />
        </div>
      </section>

      <section className={s.boostEmployability}>
        <div className={s.image}>
          <img src={wwo} alt="Magnifying glass on profile" />
        </div>
        <div className={s.content} style={{ marginLeft: "57px" }}>
          <h2>What We Offer</h2>
          <p>Inelf is not just about education— <i>Our platform encourages hands-on learning to ensure you gain real-world skills and confidence.</i></p>
          <ul>
            <li><b>Practical learning</b> to build real-world skills.</li>
            <li><b>An innovative space</b> designed for creativity and growth.</li>
            <li><b>Opportunities</b> to learn by doing, not just by watching.</li>
          </ul>
        </div>
      </section>
    </div>
  );
}
