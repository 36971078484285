import React from 'react';
import './Companies.css';
import CImage from '../../assets/images/Companyimg.png';
import Footer from '../../components/Footer/Footer';
import Pool from './Pool.jsx'
import Cu from './Cu.jsx'
import SliderCompany from './SliderCompany.jsx'

const CompanyBanner = () => {
  return (
    <>
      <div className="business-banner">
        <div className="business-banner-text">
          <h1>Connect Seamlessly with <br />
          Emerging Talent <br />
          "Drive Your Success"</h1>
        </div>
        <div className="business-banner-image">
          <img src={CImage} alt="Business" />
        </div>
      </div>
      <div style={{ marginTop: "100px" }}></div>

      <div>
            <h1 className="mission_main_heading">Reach Your Talent Pool</h1>
            <p style={{textAlign:'center',width:'80%',margin:'auto', fontSize: '21px'}}className="mission_text">Discover and connect with<br />"The brightest minds and emerging talent."</p>
      </div>

      <Pool />

      <SliderCompany />

      <Cu />

      <Footer />
    </>
  );
};

export default CompanyBanner;
