"use client"

import { useState } from "react"
import {
  IoChevronForwardCircle,
  IoCheckmarkCircle,
  IoBookOutline,
  IoCodeSlash,
  IoColorPalette,
  IoRocket,
  IoRibbon,
  IoSparkles,
  IoSearch,
  IoStar,
  IoPeople,
} from "react-icons/io5"
import CourseSlides from "../Html and css/HSlides/H1.jsx"
import CourseSlides2 from "../Html and css/HSlides/H2.jsx"
import CourseSlides3 from "../Html and css/HSlides/H3.jsx"
import CourseSlides4 from "../Html and css/HSlides/H4.jsx"
import Footer from "../../components/Footer/Footer.js"
import styles from "./HLayout.module.css"
import logo from "../../assets/images/logo.svg"
import pp from "../../assets/images/pp.png"
import { Play, BookOpen, Bell, Share2, ChevronRight, Trophy, Clock } from "lucide-react"
import confetti from "canvas-confetti"

// Define course content
const courseContent = {
  "Web Development Basics": {
    topics: [
      {
        name: "Introduction to HTML",
        content: ["What is HTML?", "How HTML Works?", "Opening Tags", "Closing Tags"],
      },
      {
        name: "HTML Elements",
        content: ["Block Elements", "Inline Elements", "Self-Closing Tags", "Attributes"],
      },
      {
        name: "HTML Forms",
        content: ["Form Structure", "Input Types", "Labels and Placeholders", "Form Validation"],
      },
      {
        name: "HTML Media",
        content: ["Images", "Audio", "Video", "Iframes"],
      },
    ],
  },
  "Styling with CSS": {
    topics: [
      {
        name: "Introduction to CSS",
        content: ["What is CSS?", "Inline vs Internal vs External CSS", "CSS Syntax", "Selectors"],
      },
      {
        name: "CSS Box Model",
        content: ["Margin", "Padding", "Border", "Box Sizing"],
      },
      {
        name: "CSS Layouts",
        content: ["Display Property", "Flexbox", "Grid", "Positioning"],
      },
    ],
  },
  "Advanced Web Design": {
    topics: [
      {
        name: "Responsive Design",
        content: ["Media Queries", "Viewport Meta Tag", "Responsive Images", "Mobile-First Design"],
      },
      {
        name: "CSS Animations",
        content: ["Transitions", "Keyframes", "Transformations", "Animation Properties"],
      },
      {
        name: "CSS Frameworks",
        content: ["Bootstrap", "Tailwind CSS", "Foundation", "Bulma"],
      },
    ],
  },
}

const CourseSection = ({
  section,
  topics,
  isActive,
  activeTopic,
  onClick,
  onTopicClick,
  completedTopics,
  toggleTopicCompletion,
  searchQuery,
}) => {
  const sectionProgress = topics.filter((t) => completedTopics.has(t.name)).length
  const filteredTopics = topics.filter((topic) => topic.name.toLowerCase().includes(searchQuery.toLowerCase()))

  const getSectionIcon = (section) => {
    switch (section) {
      case "Web Development Basics":
        return <IoCodeSlash className={styles["hlayout-section-icon-main"]} />
      case "Styling with CSS":
        return <IoColorPalette className={styles["hlayout-section-icon-main"]} />
      case "Advanced Web Design":
        return <IoRocket className={styles["hlayout-section-icon-main"]} />
      default:
        return <IoCodeSlash className={styles["hlayout-section-icon-main"]} />
    }
  }

  return (
    <div className={styles["hlayout-course-section"]}>
      <div
        className={`${styles["hlayout-section-header"]} ${isActive ? styles["hlayout-section-header-active"] : ""}`}
        onClick={onClick}
      >
        <div className={styles["hlayout-section-header-content"]}>
          <div className={styles["hlayout-section-icon-container"]}>
            {getSectionIcon(section)}
            {sectionProgress === topics.length && <IoRibbon className={styles["hlayout-section-badge"]} />}
          </div>
          <div className={styles["hlayout-section-title-wrapper"]}>
            <h3 className={styles["hlayout-section-title"]}>{section}</h3>
          </div>
          <IoChevronForwardCircle
            className={`${styles["hlayout-section-chevron"]} ${isActive ? styles["hlayout-section-chevron-rotated"] : ""}`}
          />
        </div>
      </div>

      {isActive && (
        <>
          <div className={styles["hlayout-section-progress"]}>
            <span className={styles["hlayout-progress-text"]}>
              {sectionProgress}/{topics.length} completed
            </span>
            <div className={styles["hlayout-section-progress-bar"]}>
              <div
                className={styles["hlayout-section-progress-fill"]}
                style={{ width: `${(sectionProgress / topics.length) * 100}%` }}
              />
            </div>
          </div>

          <div className={styles["hlayout-topics"]}>
            {filteredTopics.map((topic, index) => {
              const isCompleted = completedTopics.has(topic.name)
              return (
                <div
                  key={index}
                  className={`${styles["hlayout-topic"]} ${activeTopic === topic ? styles["hlayout-topic-active"] : ""} ${isCompleted ? styles["hlayout-topic-completed"] : ""}`}
                  onClick={() => onTopicClick(topic)}
                >
                  <div
                    className={styles["hlayout-topic-status"]}
                    onClick={(e) => {
                      e.stopPropagation()
                      toggleTopicCompletion(topic.name)
                    }}
                  >
                    {isCompleted ? (
                      <IoCheckmarkCircle className={styles["hlayout-topic-check"]} />
                    ) : (
                      <div className={styles["hlayout-topic-circle"]} />
                    )}
                  </div>
                  <div className={styles["hlayout-topic-content"]}>
                    <span className={styles["hlayout-topic-name"]}>{topic.name}</span>
                    <div className={styles["hlayout-topic-meta"]}>
                      <span className={styles["hlayout-topic-duration"]}>
                        <Clock size={14} /> 15 mins
                      </span>
                      <span className={styles["hlayout-topic-lessons"]}>
                        <BookOpen size={14} /> {topic.content.length} lessons
                      </span>
                      {isCompleted && <IoSparkles className={styles["hlayout-topic-sparkle"]} />}
                    </div>
                  </div>
                  <ChevronRight className={styles["hlayout-topic-arrow"]} />
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

const HLayout = () => {
  const [activeSection, setActiveSection] = useState("Web Development Basics")
  const [activeTopic, setActiveTopic] = useState(courseContent["Web Development Basics"].topics[0])
  const [showSlides, setShowSlides] = useState(false)
  const [completedTopics, setCompletedTopics] = useState(new Set())
  const [achievements, setAchievements] = useState([])
  const [searchQuery, setSearchQuery] = useState("")

  // Slide mapping: maps topic names to their corresponding slide components
  const slideMapping = {
    "Introduction to HTML": CourseSlides,
    "HTML Elements": CourseSlides2,
    "HTML Forms": CourseSlides3,
    "HTML Media": CourseSlides4,
  }

  const handleSectionClick = (section) => {
    if (activeSection === section) {
      setActiveSection(null)
    } else {
      setActiveSection(section)
      setActiveTopic(courseContent[section].topics[0])
    }
  }

  const handleTopicClick = (topic) => {
    setActiveTopic(topic)
  }

  const toggleTopicCompletion = (topicName) => {
    setCompletedTopics((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(topicName)) {
        newSet.delete(topicName)
      } else {
        newSet.add(topicName)
        checkAchievements(newSet.size)
      }
      return newSet
    })
  }

  const checkAchievements = (newSize) => {
    const unlocked = []
    if (newSize === 5) unlocked.push("5 Topics Mastered! 🎓")
    if (newSize === 10) unlocked.push("Web Novice! 🌐")
    const totalTopics = Object.values(courseContent).reduce((acc, section) => acc + section.topics.length, 0)
    if (newSize === totalTopics) {
      unlocked.push("Course Master! 🏆")
      confetti({ particleCount: 100, spread: 70, origin: { y: 0.6 } })
    }

    if (unlocked.length > 0) {
      setAchievements((prev) => [...prev, ...unlocked])
      setTimeout(() => setAchievements([]), 3000)
    }
  }

  const handleSearch = (e) => {
    setSearchQuery(e.target.value)
  }

  const SlideComponent = activeTopic ? slideMapping[activeTopic.name] : null

  return (
    <div className={styles["hlayout-root"]}>
      <div className={`${styles["hlayout-page-wrapper"]} ${showSlides ? styles["hlayout-blur-background"] : ""}`}>
        <div className={styles["hlayout-course-container"]}>
          <div className={styles["hlayout-left-panel"]}>
            <div className={styles["hlayout-panel-header"]}>
              <div className={styles["hlayout-course-header-top"]}>
                <h2 className={styles["hlayout-course-heading"]}>
                  <IoBookOutline className={styles["hlayout-course-icon"]} />
                  HTML & CSS Course
                </h2>
                <div className={styles["hlayout-course-meta"]}>
                  <div className={styles["hlayout-meta-item"]}>
                    <IoStar className={styles["hlayout-meta-icon"]} />
                    4.9 (1.2k ratings)
                  </div>
                  <div className={styles["hlayout-meta-item"]}>
                    <IoPeople className={styles["hlayout-meta-icon"]} />
                    {(12500).toLocaleString()} students
                  </div>
                </div>
              </div>

              <div className={styles["hlayout-search-bar"]}>
                <IoSearch className={styles["hlayout-search-icon"]} />
                <input type="text" placeholder="Search topics..." value={searchQuery} onChange={handleSearch} />
              </div>
            </div>

            {Object.entries(courseContent).map(([section, { topics }]) => (
              <CourseSection
                key={section}
                section={section}
                topics={topics}
                isActive={activeSection === section}
                activeTopic={activeTopic}
                onClick={() => handleSectionClick(section)}
                onTopicClick={handleTopicClick}
                completedTopics={completedTopics}
                toggleTopicCompletion={toggleTopicCompletion}
                searchQuery={searchQuery}
              />
            ))}
          </div>

          <div className={styles["hlayout-right-panel"]}>
            <div className={styles["hlayout-achievement-container"]}>
              {achievements.map((text, index) => (
                <div key={index} className={styles["hlayout-achievement-toast"]}>
                  <Trophy className={styles["hlayout-achievement-icon"]} />
                  {text}
                </div>
              ))}
            </div>

            <div className={styles["hlayout-content-container"]}>
              <div className={styles["hlayout-content-header"]}>
                <div className={styles["hlayout-brand"]}>
                  <img src={logo || "/placeholder.svg"} alt="Inelf Logo" className={styles["hlayout-brand-logo"]} />
                  <span className={styles["hlayout-brand-name"]}>inelf.com</span>
                </div>
              </div>

              <div className={styles["hlayout-content-body"]}>
                <h1 className={styles["hlayout-content-title"]}>{activeTopic?.name || "HTML & CSS Course"}</h1>
                {activeTopic ? (
                  <div className={styles["hlayout-content-list"]}>
                    {activeTopic.content.map((item, index) => (
                      <div key={index} className={styles["hlayout-content-item"]}>
                        <IoCheckmarkCircle className={styles["hlayout-check-icon"]} />
                        <span>{item}</span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>Please select a topic.</p>
                )}
                <button className={styles["hlayout-get-started-btn"]} onClick={() => setShowSlides(true)}>
                  GET STARTED
                </button>
              </div>

              <div className={styles["hlayout-illustration"]}>
                <img
                  src={pp || "/placeholder.svg"}
                  alt="HTML & CSS Illustration"
                  className={styles["hlayout-illustration-image"]}
                />
              </div>
            </div>

            <div className={styles["hlayout-action-buttons"]}>
              <button className={`${styles["hlayout-action-btn"]} ${styles["hlayout-video-btn"]}`}>
                <Play className={styles["hlayout-action-icon"]} />
                <span>Video</span>
              </button>
              <button className={`${styles["hlayout-action-btn"]} ${styles["hlayout-quiz-btn"]}`}>
                <BookOpen className={styles["hlayout-action-icon"]} />
                <span>Quiz</span>
              </button>
              <button className={`${styles["hlayout-action-btn"]} ${styles["hlayout-plan-btn"]}`}>
                <Bell className={styles["hlayout-action-icon"]} />
                <span>Learning Plan</span>
              </button>
              <button className={`${styles["hlayout-action-btn"]} ${styles["hlayout-share-btn"]}`}>
                <Share2 className={styles["hlayout-action-icon"]} />
                <span>Share Link</span>
              </button>
            </div>

            <div className={styles["hlayout-installation-guide"]}>
              <div className={styles["hlayout-guide-tabs"]}>
                <button className={`${styles["hlayout-tab"]} ${styles["hlayout-tab-active"]}`}>Installation</button>
                <button className={styles["hlayout-tab"]}>Resources</button>
                <button className={styles["hlayout-tab"]}>Cheatsheets</button>
              </div>
              <h2>HTML & CSS Installation and Getting Started Guide</h2>
              <ol className={styles["hlayout-installation-list"]}>
                <li>
                  <strong>Download a code editor (e.g., VS Code)</strong>
                </li>
                <li>
                  <strong>Set up your development environment</strong>
                </li>
                <li>
                  <strong>Create your first HTML file</strong>
                </li>
                <li>
                  <strong>Link your CSS file</strong>
                </li>
              </ol>
            </div>
          </div>
        </div>

        {showSlides && (
          <div className={styles["hlayout-slides-overlay"]}>
            {SlideComponent ? (
              <SlideComponent onClose={() => setShowSlides(false)} />
            ) : (
              <div className={styles["hlayout-placeholder-slide"]}>
                <h2>Slide Not Available Yet</h2>
                <p>Slides for this topic will be added soon.</p>
                <button onClick={() => setShowSlides(false)}>Close</button>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}

export default HLayout