import React from "react";
import "./Header.css";
import logo from "../../assets/images/logo.svg";
import exploreIcon from "../../assets/images/ExploreSiteBTN.png";
import searchIcon from "../../assets/images/search_icon.png";
import { FaBell, FaUser } from "react-icons/fa";
import { Menu, Dropdown } from "antd";

const notifications = (
  <Menu>
    <Menu.Item key="1"><b> New announcement: </b>  Check out our latest courses!</Menu.Item>
    <Menu.Item key="2"><b>Reminder: </b> Complete your profile for personalized recommendations</Menu.Item>
  </Menu>
);

const Header = ({ openModal }) => {
  return (
    <header className="header">
    <a href="/" className="header__logo-link">
    <div className="header__logo">
      <img src={logo} alt="Inelf Logo" className="header__logo-img" />
      <span className="header__title">inelf</span>
    </div>
    </a>

      <div className="header__search">
        <input
          type="text"
          style={{ marginLeft: "20px" }}
          placeholder="Search for a course..."
          className="header__search-input"
        />
        <img src={searchIcon} alt="Search Icon" className="header__search-icon" />
      </div>

      <Dropdown overlay={notifications} trigger={['click']}>
        <button className="header__notification-btn">
          <FaBell className="header__notification-icon" />
        </button>
      </Dropdown>

      <div className="header__divider2" />
      
      <div className="header-right">
        <button className="header__explore-btn">
          <img src={exploreIcon} alt="Explore Icon" className="header__explore-icon" />
          <span>Explore Site</span>
        </button>

        <div className="header__auth-buttons">
          <div className="header__divider" />
          <button className="header__login-btn" onClick={openModal}>
            <FaUser className="header__user-icon" />
            Login
          </button>
          <div className="header__divider" />
          <button className="header__register-btn" onClick={openModal}>Register</button>
        </div>
      </div>
    </header>
  );
};

export default Header;
