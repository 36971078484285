// LayoutWithHeader.jsx
import { Outlet } from 'react-router-dom';
import Header from '../components/Header/Header';

const LayoutWithHeader = ({ openModal }) => {
  return (
    <>
      <Header openModal={openModal} />
      <Outlet />
    </>
  );
};

export default LayoutWithHeader;
